<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Herramientas</b>
      </div>
    </b-alert>
    
    <b-row class="ml-3 mb-4">
      <b-col class="col-6">
        <h3 class="mb-3">Filtros</h3>
        <h4 class="mb-1">Categorías</h4>
        <b-form-select class="mb-3" v-model="category" :options="categories.filter((item, i, ar) => ar.indexOf(item) === i)"></b-form-select>
        <h4 class="mb-1">Países</h4>
        <b-form-select class="" v-model="countryFilter" :options="countryFilters.filter((item, i, ar) => ar.indexOf(item) === i)"></b-form-select>
      </b-col>
    </b-row>
    
    <b-row>
      <div class="col-lg-3 col-md-4 col-sm-6" align-self="center" v-for="link in linksList" :key="link.id">
          <b-card
            no-body
            :img-src="link.icon ? link.icon : 'media/logos/placeholder.png'"
            img-alt="Image"
            img-top
            tag="article"
            style=""
            class="m-2"
            img-height="230px"
            @click="openLink(link.url)"
          >
            <b-card-text class="">
              <b-container>
                <b-row class="d-flex" align-v="center" >
                  <b-col class="d-inline-flex">
                    <p class="mt-3">{{link.country? countries[link.country].name : ''}}</p>
                    <img v-if="link.country && link.country !== 'Otros'" :src="countries[link.country].flag"  class="d-inline-flex m-2" alt="" width="40px">
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col v-if="link.link_title">
                    <h6>{{link.subcategory_name}}</h6>
                    <h4>{{link.link_title}}</h4>
                  </b-col>
                  <b-col v-if="link.url" class="mb-2">
                    <b-button :href="link.url" target="_blank" variant="primary">Ver</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-card-text>
          </b-card>
        </div>
      </b-row>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_LINKS } from "@/core/services/store/cvtas.module";

export default {
  data() {
    return {
      category: null,
      countryFilter: null,
      linksList: [],
      originallinksList: [],
      categories: ['todos'],
      countryFilters: ['todos'],
      countries: {
        "GT": {
          flag: "media/flags/guatemala.png",
          name: "Guatemala"
        },
          "ES": {
          flag: "media/flags/el-salvador.png",
          name: "El Salvador"
        },
          "BZ": {
          flag: "media/flags/belize.png",
          name: "Belice"
        },
          "HN": {
          flag: "media/flags/honduras.png",
          name: "Honduras"
        },
        "NI": {
          flag: "media/flags/nicaragua.png",
          name: "Nicaragua"
        },
        "PA": {
          flag: "media/flags/panama.png",
          name: "Panama"
        },
        "CR": {
          flag: "media/flags/costa-rica.png",
          name: "Costa Rica"
        },
        "Otros": {
          flag: "media/flags/otros.png",
          name: "Otros"
        }
      },
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    openLink(url){
      window.open(url, '_blank').focus();
    },
    fetchLinks(){
      this.$store.dispatch(GET_LINKS).then((response) => {
          this.linksList = JSON.parse(response).links;
          this.originallinksList = this.linksList;
          for(var item of this.linksList){
            if(item.category_name){
              this.categories.push(item.category_name);
            } 
            if(item.country){
              this.countryFilters.push(item.country);
            } 
          }
          console.log(this.linksList);
      }).catch((err) => {console.log(err)});
    },
  },
  watch: {
    category: function (newVal, oldVal) {
      console.log(newVal, oldVal);
      if(newVal == 'todos'){
        this.linksList = this.originallinksList; 
      } else {
          this.linksList = this.originallinksList.filter(link => (link.category_name ===  newVal)); 
      }
    },
    countryFilter: function (newVal, oldVal) {
      if(newVal == 'todos'){
        this.linksList = this.originallinksList; 
      } else {
          this.linksList = this.originallinksList.filter(link => (link.country ===  newVal));  
      }
      
    },
  },
  beforeMount(){
    this.fetchLinks();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "Card" }
    ]);
  }
};
</script>

<style>
  .card-img, .card-img-top {
    object-fit: cover;
  }
</style>
